'use client';

import { useEffect } from 'react';

import {
    Alert,
    Box,
    Column,
    Columns,
    Container,
    Icon,
    IconName,
    IconSize,
    Link,
    Section,
    Text,
    TextLevels,
    Title,
    TitleLevels,
    TitleMarkup,
    TrilogyColor,
    TypographyBold,
} from '@bytel/trilogy-react';

import { paths } from '@helpers/path';
import { sendPageViewTracking } from '@helpers/tracking';

function NotFoundHeader() {
    return (
        <Section className='has-text-centered has-pattern-dark'>
            <Container>
                <Icon name={IconName.INTERNAL_FRAUD} size={IconSize.HUGE} color={TrilogyColor.BACKGROUND} />
                <Title level={TitleLevels.ONE} markup={TitleMarkup.H1} inverted className='is-marginless'>
                    Cette page est introuvable
                </Title>
                <Title level={TitleLevels.FOUR} markup={TitleMarkup.H2} inverted>
                    Il est possible qu&apos;elle ait été supprimée, ou déplacée.
                </Title>
                <Title level={TitleLevels.FOUR} markup={TitleMarkup.H2} inverted>
                    Pas de panique, on vous aide à retrouver votre chemin.
                </Title>
            </Container>
        </Section>
    );
}

function NotFoundContent() {
    return (
        <Section backgroundColor={TrilogyColor.NEUTRAL_FADE}>
            <Container>
                <Columns>
                    <Column size={5}>
                        <Title level={TitleLevels.THREE} markup={TitleMarkup.H2}>
                            De quoi avez-vous besoin ?
                        </Title>
                        <Text level={TextLevels.ONE} marginless>
                            Téléphone, forfait mobile, abonnement internet, assistance...
                        </Text>
                        <Text level={TextLevels.ONE}>On a forcément quelque chose pour vous.</Text>
                    </Column>
                    <Column size={7}>
                        <Columns multiline>
                            <Column size={6}>
                                <InfoBox
                                    title='Forfaits mobile'
                                    description="Avec ou sans smartphone, promis, on a le forfait qu'il vous faut&nbsp;!"
                                    linkText='Découvrir nos offres'
                                    linkHref={paths.plans}
                                />
                            </Column>
                            <Column size={6}>
                                <InfoBox
                                    title='Box internet'
                                    description='Fibre, ADSL, 4G box : à chacun sa solution.'
                                    linkText='Tester votre éligibilité'
                                    linkHref={paths.homeFai}
                                />
                            </Column>
                            <Column size={6}>
                                <InfoBox
                                    title='Assistance'
                                    description='Besoin de nous contacter pour une question technique ou commerciale&nbsp;?'
                                    linkText='Obtenir une réponse'
                                    linkHref='https://www.assistance.bouyguestelecom.fr/s/'
                                />
                            </Column>
                            <Column size={6}>
                                <InfoBox
                                    title='Espace client'
                                    description='Suivre votre conso, accéder à votre facture, modifier votre offre...'
                                    linkText='Se connecter'
                                    linkHref={paths.clientSpace}
                                />
                            </Column>
                            <Column>
                                <Alert
                                    description={
                                        <Text level={TextLevels.ONE}>
                                            <span>Vous pouvez aussi revenir sur </span>
                                            <Link href={paths.index}>la page d&apos;accueil</Link>
                                            <span> ou utiliser le menu situé en haut de page</span>
                                        </Text>
                                    }
                                    className='is-fullwidth'
                                    display
                                    iconName={IconName.LIGHTBULB_ON}
                                />
                            </Column>
                        </Columns>
                    </Column>
                </Columns>
            </Container>
        </Section>
    );
}

interface InfoBoxProps {
    title: string;
    description: string;
    linkText: string;
    linkHref: string;
}

function InfoBox({ title, description, linkText, linkHref }: Readonly<InfoBoxProps>) {
    return (
        <Box fullheight className='is-spaced-between'>
            <div>
                <Text level={TextLevels.TWO} typo={TypographyBold.TEXT_WEIGHT_SEMIBOLD}>
                    {title}
                </Text>
                <Text level={TextLevels.TWO}>{description}</Text>
            </div>
            <Link href={linkHref}>{linkText}</Link>
        </Box>
    );
}

export function NotFound() {
    useEffect(() => {
        sendPageViewTracking(false, {
            page: 'Erreur_404',
            pageCategory: 'Erreur',
            universe: 'Boutique_web',
        });
    }, []);

    return (
        <>
            <NotFoundHeader />
            <NotFoundContent />
        </>
    );
}
