'use client';

import type { ComponentProps } from 'react';
import React, { ElementType, Fragment, useState } from 'react';
import { SSIInclude } from 'react-ssi-include';

import { getAppConfig } from '@services/config';

interface SSIBlockProps<T extends ElementType = typeof Fragment> {
    url: string;
    tagId: string;
    container?: T;
    containerProps?: Omit<ComponentProps<T>, 'children'>;
}

/**
 * Return an SSI block
 * @param url Partial URL (/partials/xxxx)
 * @param tagId Tag for html
 * @param container Wrapper for the SSI block
 * @returns
 */
export function SSIBlock<T extends ElementType = typeof Fragment>({
    url,
    tagId,
    // @ts-expect-error - We don't care about the children prop
    container: Container = 'div',
    containerProps,
}: Readonly<SSIBlockProps<T>>) {
    const [shouldDisplay, setShouldDisplay] = useState(true);

    if (!shouldDisplay) {
        return null;
    }

    return (
        // @ts-expect-error - We don't care about the children prop
        <Container data-cy='block-cms' data-url={url} {...containerProps}>
            <SSIInclude
                tagId={tagId}
                url={
                    process.env.NODE_ENV === 'development'
                        ? getAppConfig().assets.cms.url + url.replace('/partials', '')
                        : url
                }
                onClientSideFetch={(error) => {
                    if (error) {
                        setShouldDisplay(false);
                    }
                }}
            />
        </Container>
    );
}
