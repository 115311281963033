import { ProductInterface } from '@bytel/product-wall/types';

import { DiscountType } from '@app-types/discount';
import { FundingType } from '@app-types/funding';
import { PriceType } from '@app-types/price';
import { ReviewsInfoType } from '@app-types/review';
import { SimulatedQuote } from '@app-types/simulator/virtual-cart';

import { StockConfigType } from '@constants/stock';

export type Product = ProductInterface & {
    name: string;
    urlKey: string;
    details: PriceFundingsDetails;
    inStock: boolean;
    type?: ProductType;
    categories: string[];
    simulatedCart?: SimulatedCartProduct;
    quoteIndex?: number;
    quantity?: number;
    partnerId?: string;
    color?: string;
    capacity?: string;
};

export type SimulatedCartProduct = {
    quotes: SimulatedQuote[];
    fundings: FundingType[];
};

export type PriceFundingsDetails = {
    price: PriceType;
    fundings: FundingType[];
    discounts: DiscountType[];
};

export enum ProductType {
    PHONE_SIMPLE = 'phone_simple',
    PHONE = 'phone',
    ACCESSORY = 'accessory',
    ACCESSORY_CONFIGURABLE = 'accessory_configurable',
    BOX = 'box',
    FAIM_UNLIMITED = 'faim_unlimited',
    FAI = 'fai',
    FAI_SERVICE = 'fai_service',
    PLAN_PREMIUM = 'plan_premium',
    PLAN_PREMIUM_RENEWAL = 'plan_premium_renew',
    OPTION_DEFAULT = 'option_default',
    OPTION_SALABLE = 'option_salable',
}

export enum ProductFeatureType {
    COLOR = 'color',
    CAPACITY = 'capacity',
}

export type BaseProductFull = Omit<Product, 'details'> & {
    campaignId?: string;
    das: { name: string; value: string }[];
    details?: PriceFundingsDetails; // in order to make it optional for renewal
    ecoTax?: string;
    entityId: string;
    highlightsCms?: string;
    manufacturer: string;
    manufacturerCode: string;
    medias: string[];
    metaDescription?: string;
    packContent?: string;
    prices: { initial: number; withOdr?: number; odr?: number }; // only initial for Parent
    reparability: {
        note?: number;
        pdf?: string;
    };
    sku: string;
    specialOffersCms?: string;
    specificationsCms?: string;
    title: string;
    type: ProductType;
    urlKey: string;
    videoUrl?: string;
};

export type ProductParentFull = BaseProductFull & {
    childs: Record<string, ProductChildFull>;
    features: {
        color: FeatureType[];
        capacity: FeatureType[];
    };
    featuresRules: Record<string, string[]>;
    productIds: string[];
    recovery?: {
        bonus: number;
        dates: {
            start: string;
            end: string;
        };
    };
    reviews?: ReviewsInfoType;
};

export type ProductChildFull = BaseProductFull & {
    capacity?: string;
    characteristics: CharacteristicsChildProduct;
    color: string;
    colorCode: string;
    parentTitle: string;
    isOnlyWeb: boolean;
    stockDetails: ProductStockDetails;
};

export type ProductStockDetails = {
    closestShippingDate?: string;
    hasClickAndCollect: boolean;
    hasFreeShipping: boolean;
    hasStockInStore: boolean;
    isAvailableInStore: boolean;
    isPreresa: boolean;
    stock: StockConfigType;
    isPreorder: boolean;
    isInStock: boolean;
    isSoonExhausted: boolean;
    isAvailableSoon: boolean | undefined;
};

export type FeatureType = {
    name: string;
    value: string;
};

export type CharacteristicParentProduct = {
    battery: {
        capacity?: string;
        isRemovable?: boolean;
        talkTime?: string;
        standbyTime?: string;
    };
    capacityAvailable?: string;
    capacitySd?: string;
    connectionRange?: string;
    depth?: string;
    diagonal?: string;
    hasAutoFocus?: boolean;
    height?: string;
    isDoubleCamera?: boolean;
    isESimCompatible?: boolean;
    isSimMultiple?: boolean;
    length?: string;
    material?: string;
    os: {
        name?: string;
        version?: string;
    };
    processor: {
        core?: string;
        speed?: string;
    };
    resolution: {
        width?: string;
        height?: string;
        sensor?: string;
    };
    screenTechnology?: string;
    weight?: string;
    width?: string;
    wifi?: boolean;
};

export type CharacteristicsChildProduct = Partial<CharacteristicParentProduct> & {
    included?: string;
    nonIncluded?: string;
    charging?: {
        head: boolean;
        maxLoadPower?: string;
        minLoadPower?: string;
    };
    output: {
        memoryCard?: string;
        charger?: string;
        audio?: string;
    };
};
