import { getAppConfig } from '@services/config';
import { trackingManager } from '@services/tracking';

import { PageViewTrackingType, TrackingType } from '@app-types/tracking';

import { noop } from '@helpers/try-catch';

const appConfig = getAppConfig();

export function formatTagText(text?: string) {
    if (!text) return '';
    return text
        .split(' ')
        .join('_')
        .split('&nbsp;')
        .join('_')
        .split('é')
        .join('e')
        .split('è')
        .join('e')
        .split('ê')
        .join('e')
        .split('à')
        .join('a');
}

export function sendTracking(isRenewal: boolean, data: TrackingType) {
    if (!appConfig.tracking.enabled) {
        return;
    }
    trackingManager.sendTracking(isRenewal, data).catch(noop);
}

export function sendPageViewTracking(isRenewal: boolean, data: PageViewTrackingType) {
    sendTracking(isRenewal, data);
}
