'use client';

import { SessionProvider, SessionProviderProps } from 'next-auth/react';
import React, { useEffect } from 'react';

import { TrilogyProvider } from '@bytel/trilogy-react';

import { signIn } from '@helpers/authentication';
import { localApiPaths } from '@helpers/path';
import { isSessionExpired } from '@helpers/session';

export function Provider({ children }: { children: React.ReactNode }) {
    return <TrilogyProvider withStyles={false}>{children}</TrilogyProvider>;
}

export function NextAuthProvider({ children, session }: SessionProviderProps) {
    useEffect(() => {
        if (isSessionExpired(session)) {
            document.cookie = 'next-session-token=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT';
            void signIn();
        }
    }, [session?.user?.sub, session?.user?.exp]);

    return (
        <SessionProvider session={session} basePath={localApiPaths.auth}>
            {children}
        </SessionProvider>
    );
}
